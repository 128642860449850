import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import ReCAPTCHA from 'react-google-recaptcha';
import { typography } from '@style-guide/config';

import Button from '@style-guide/components/Button';

import { required, ccNumber, allNumbers } from '@services/FormValidators';
import ErrorMessage from '@components/ErrorMessage';
import FormFieldGroup from '@components/FormFieldGroup';
import FormFieldSubGroup from '@components/FormFieldSubGroup';
import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormSelect from '@components/ReduxFormSelect';
import SuccessMessage from '@components/SuccessMessage';
import CreditCardDetails from '@components/CreditCardDetails';
import PopupMessage from '@components/PopupMessage/PopupMessage';
import ReduxFormCheckbox from '@components/ReduxFormCheckbox';
import {
  cardOptions,
  monthOptions,
  expirationYearOptions,
  stateOptions,
} from '@config';

const PayPalButtonWrapper = styled.div`
  margin: 30px 0 0;
`;

PayPalButtonWrapper.displayName = 'PayPalButtonWrapper';

const SectionHeading = styled.h4`
  font-size: ${rem('20px', typography.baseFontSize)};
  font-weight: 600;
  margin: 10px 15px;
`;
SectionHeading.displayName = 'SectionHeading';

const UpdatePaymentForm = ({
  pristine,
  invalid,
  handleSubmit,
  creditCardSaveError = '',
  creditCardSuccessMessage = '',
  loading = false,
  removeSavedPaymentMethod,
  creditCardRemoveError = '',
  removeSuccessMethod = '',
  removeDisabled = false,
  onRecaptchaChange,
  captchaValid = false,
  recordId = 0,
  initialValues,
  onHandleSaveClick,
  showPopupOnCardSave,
  onDisclaimClick,
  autoRenew,
  disclaimerChecked,
}) => (
  <form
    id="updatePaymentForm"
    onSubmit={handleSubmit}
  >
    {(!recordId || recordId <= 0) && (
    <SectionHeading>Credit Card</SectionHeading>
    )}
    {recordId > 0 && initialValues.paymentTypeID !== 48 && (
    <SectionHeading>Credit Card</SectionHeading>
    )}
    {recordId > 0 && initialValues.paymentTypeID === 48 && (
    <SectionHeading>PayPal</SectionHeading>
    )}
    {(!recordId || recordId <= 0) && (
    <FormFieldGroup>
      <FormFieldWrapper>
        <Field
          id="cardType"
          label="Credit Card Type*"
          options={cardOptions}
          name="paymentTypeID"
          component={ReduxFormSelect}
          validate={required}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Field
          name="ccPartial"
          id="cardNumber"
          type="tel"
          label="Card Number*"
          component={ReduxFormInput}
          validate={[required, allNumbers, ccNumber]}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Field
          name="firstName"
          id="firstName"
          type="text"
          label="First Name*"
          component={ReduxFormInput}
          validate={required}
        />
        <Field
          name="lastName"
          id="lastName"
          type="text"
          label="Last Name*"
          component={ReduxFormInput}
          validate={required}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <FormFieldSubGroup>
          <Field
            name="ccExpireMonth"
            id="expirationMonth"
            label="Exp. Month*"
            options={monthOptions}
            component={ReduxFormSelect}
            validate={required}
          />
          <Field
            name="ccExpireYear"
            id="expirationYear"
            label="Exp. Year*"
            options={expirationYearOptions}
            component={ReduxFormSelect}
            validate={required}
          />
        </FormFieldSubGroup>
        <Field
          name="securityCode"
          id="securityCode"
          type="tel"
          maxLength="5"
          label="Security Code*"
          component={ReduxFormInput}
          validate={[required, allNumbers]}
        />
      </FormFieldWrapper>
    </FormFieldGroup>
    )}
    {recordId > 0 && initialValues.paymentTypeID !== 48 && (
      <FormFieldGroup>
        <div>
          <CreditCardDetails
            cardType={cardOptions.filter(c =>
              c.value === initialValues.paymentTypeID.toString())[0].label}
            cardNumber={initialValues.ccPartial}
            expirationMonth={initialValues.ccExpireMonth}
            expirationYear={expirationYearOptions.filter(e =>
              e.value === initialValues.ccExpireYear.toString())[0].label}
          />
        </div>
        <FormFieldWrapper>
          <Button
            type="button"
            onClick={removeSavedPaymentMethod}
            disabled={loading || removeDisabled}
          >
            Remove Saved Credit Card
          </Button>
        </FormFieldWrapper>
      </FormFieldGroup>
    )}
    {recordId > 0 && initialValues.paymentTypeID === 48 && (
      <FormFieldGroup>
        <FormFieldWrapper>
          <Button
            type="button"
            onClick={removeSavedPaymentMethod}
            disabled={loading || removeDisabled}
          >
            REMOVE SAVED PAYPAL INFORMATION
          </Button>
        </FormFieldWrapper>
      </FormFieldGroup>
    )}
    <SectionHeading>Billing Address</SectionHeading>
    <FormFieldGroup>
      <FormFieldWrapper>
        <Field
          name="billingAddress1"
          id="addressLine1"
          label="Street Address*"
          component={ReduxFormInput}
          validate={required}
        />
        <Field
          name="billingAddress2"
          id="addressLine2"
          label="Suite, Apt #, etc"
          component={ReduxFormInput}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <Field
          name="billingAddressCity"
          id="city"
          label="City*"
          component={ReduxFormInput}
          validate={required}
        />
        <FormFieldSubGroup>
          <Field
            name="billingAddressState"
            id="state"
            label="State*"
            options={stateOptions}
            component={ReduxFormSelect}
            validate={required}
          />
          <Field
            name="billingAddressZip"
            id="zipCode"
            type="tel"
            label="Zip Code*"
            maxLength="5"
            component={ReduxFormInput}
            validate={required}
          />
        </FormFieldSubGroup>
      </FormFieldWrapper>
      <FormFieldWrapper>
        <span><b>*Required</b></span>
      </FormFieldWrapper>
    </FormFieldGroup>
    {creditCardSaveError && (
      <FormFieldGroup>
        <ErrorMessage>
          <span>{creditCardSaveError}</span>
        </ErrorMessage>
      </FormFieldGroup>
    )}
    {creditCardSuccessMessage && (
      <FormFieldGroup>
        <SuccessMessage>
          <span>{creditCardSuccessMessage}</span>
        </SuccessMessage>
      </FormFieldGroup>
    )}
    {creditCardRemoveError && (
      <FormFieldGroup>
        <ErrorMessage>
          <span>{creditCardRemoveError}</span>
        </ErrorMessage>
      </FormFieldGroup>
    )}
    {removeSuccessMethod && (
      <FormFieldGroup>
        <SuccessMessage>
          <span>{removeSuccessMethod}</span>
        </SuccessMessage>
      </FormFieldGroup>
    )}
    <FormFieldGroup>
      <ReCAPTCHA
        sitekey="6LeSVtwUAAAAADr4YotVlpu-6s41pa0PcpVuQZQ-"
        onChange={onRecaptchaChange}
      />
    </FormFieldGroup>

    {showPopupOnCardSave && (
    <PopupMessage
      onHandleClick={onHandleSaveClick}
      heading="Authorization for Automatic Renewal"
      bodyContent={(
        <Field
          id="isDisclaimerChecked"
          label={(
            <div>
              <span>I am currently enrolled in automatic renewal. By checking this box,
                    I authorize the OSBA to use this credit card to automatically
                    renew my membership as I have previously structured it.
                    I can make changes to my plan or cancel at
                    any time by contacting the
              </span>
              <a href="https://www.ohiobar.org/contact-us/" rel="noopener noreferrer" target="_blank">,
                <u>OSBA Member Service Center</u>
              </a>
              <span>; however, I understand that there are no refunds
                                or credits for partial months or years.
              </span>
            </div>
                    )}
          name="isDisclaimerChecked"
          props={{
            value: true,
          }}
          type="checkbox"
          onChange={onDisclaimClick}
          component={ReduxFormCheckbox}
        />
      )}
      submitButton={(
        <Button
          type="Submit"
          disabled={pristine || invalid || loading || !disclaimerChecked}
        >
              Save
        </Button>
        )}
    />
    )}

    <FormFieldGroup>
      <FormFieldWrapper>
        <FormFieldSubGroup>
          {recordId <= 0 && autoRenew && (
          <Button
            type="button"
            onClick={onHandleSaveClick}
            disabled={pristine || invalid || loading || !captchaValid}
          >
            Save
          </Button>
          )}
          {((recordId > 0 && autoRenew) || !autoRenew) && (
          <Button
            type="Submit"
            disabled={pristine || invalid || loading || !captchaValid}
          >
            Save
          </Button>
          )}
        </FormFieldSubGroup>
      </FormFieldWrapper>
    </FormFieldGroup>
  </form>
);

UpdatePaymentForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  creditCardSaveError: PropTypes.string,
  creditCardSuccessMessage: PropTypes.string,
  loading: PropTypes.bool,
  removeSavedPaymentMethod: PropTypes.func.isRequired,
  creditCardRemoveError: PropTypes.string,
  removeSuccessMethod: PropTypes.string,
  removeDisabled: PropTypes.bool,
  onRecaptchaChange: PropTypes.func.isRequired,
  captchaValid: PropTypes.bool,
  onHandleSaveClick: PropTypes.func.isRequired,
  recordId: PropTypes.number,
  initialValues: PropTypes.shape({
    paymentTypeID: PropTypes.number.isRequired,
    ccExpireMonth: PropTypes.string,
    ccExpireYear: PropTypes.string.isRequired,
    ccPartial: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  showPopupOnCardSave: PropTypes.bool.isRequired,
  onDisclaimClick: PropTypes.func.isRequired,
  autoRenew: PropTypes.bool.isRequired,
  disclaimerChecked: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'updatePaymentForm',
  enableReinitialize: true,
})(UpdatePaymentForm);
