import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import { Provider } from 'react-redux';
import concat from 'lodash/concat';
import { breakpoints } from '@style-guide/config';
import { getCart } from '@redux/modules/cart';
import Alert from '@components/Alert';
import Store from '@redux';
import HeaderContainer from './components/HeaderContainer';
import DesktopNav from './components/DesktopNav';
import MobileNav from './components/MobileNav';

const GlobalStyle = createGlobalStyle`
  body,
  html {
    font-size: 16px;
  }

  @media (min-width: ${breakpoints.xxxl}) {
    body,
    html {
      font-size: 18px;
    }
  }
`;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getCart());
  }

  render() {
    const {
      alertTitle,
      alertMessage,
      checkoutLink,
      headerData,
      headerLogo,
      isAuthenticated,
      isAnademOrAdmin,
      isMember,
      loginLink,
      logoutLink,
      shoppingCartLink,
      userName,
    } = this.props;

    let utilityNavItems = headerData.utilityNavigationItems;
    let primaryNavItems = headerData.primaryNavigationItems;

    if (isAuthenticated) {
      if (isMember) {
        if (isAnademOrAdmin) {
          utilityNavItems = concat(headerData.utilityNavigationItemsAnademAdmin,
            headerData.utilityNavigationItemsMember, utilityNavItems);
        } else {
          utilityNavItems = concat(headerData.utilityNavigationItemsMember, utilityNavItems);
        }
        primaryNavItems = concat(headerData.primaryNavigationItemsMember, primaryNavItems);
      } else {
        if (isAnademOrAdmin) {
          utilityNavItems = concat(headerData.utilityNavigationItemsAnademAdmin,
            headerData.utilityNavigationItemsNonMember, utilityNavItems);
        } else {
          utilityNavItems = concat(headerData.utilityNavigationItemsNonMember, utilityNavItems);
        }
        primaryNavItems = concat(headerData.primaryNavigationItemsNonMember, primaryNavItems);
      }
    } else {
      utilityNavItems = concat(headerData.utilityNavigationItemsLoggedOut, utilityNavItems);
      primaryNavItems = concat(headerData.primaryNavigationItemsLoggedOut, primaryNavItems);
    }

    return (
      <Provider store={this.store}>
        <GlobalStyle />
        <header
          id="header"
        >
          {(alertTitle || alertMessage) && (
            <Alert
              title={alertTitle}
              message={alertMessage}
            />
          )}
          <HeaderContainer>
            <DesktopNav
              headerData={headerData}
              headerLogo={headerLogo}
              checkoutLink={checkoutLink}
              utilityNavItems={utilityNavItems}
              primaryNavItems={primaryNavItems}
              isAuthenticated={isAuthenticated}
              loginLink={loginLink}
              logoutLink={logoutLink}
              shoppingCartLink={shoppingCartLink}
              userName={userName}
            />
            <MobileNav
              alertTitle={alertTitle}
              alertMessage={alertMessage}
              checkoutLink={checkoutLink}
              headerData={headerData}
              headerLogo={headerLogo}
              primaryNavItems={primaryNavItems}
              isAuthenticated={isAuthenticated}
              loginLink={loginLink}
              logoutLink={logoutLink}
              shoppingCartLink={shoppingCartLink}
              userName={userName}
            />
          </HeaderContainer>
        </header>
      </Provider>
    );
  }
}

Header.propTypes = {
  alertTitle: PropTypes.string,
  alertMessage: PropTypes.string,
  headerData: PropTypes.shape({
    flyout: PropTypes.object,
    functionalLinks: PropTypes.array,
    legalLinks: PropTypes.array,
    primaryNavigationItems: PropTypes.array,
    utilityNavigationItems: PropTypes.array,
    utilityNavigationItemsMember: PropTypes.array,
    primaryNavigationItemsMember: PropTypes.array,
    utilityNavigationItemsAnademAdmin: PropTypes.array,
    utilityNavigationItemsNonMember: PropTypes.array,
    primaryNavigationItemsNonMember: PropTypes.array,
    utilityNavigationItemsLoggedOut: PropTypes.array,
    primaryNavigationItemsLoggedOut: PropTypes.array,
  }).isRequired,
  headerLogo: PropTypes.string.isRequired,
  checkoutLink: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool,
  isAnademOrAdmin: PropTypes.bool,
  isMember: PropTypes.bool,
  loginLink: PropTypes.string.isRequired,
  logoutLink: PropTypes.string.isRequired,
  shoppingCartLink: PropTypes.string.isRequired,
  userName: PropTypes.string,
};

Header.defaultProps = {
  alertTitle: '',
  alertMessage: '',
  isAuthenticated: false,
  isAnademOrAdmin: false,
  isMember: false,
  userName: '',
};

Header.displayName = 'Header';

export default Header;
