import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import DatePicker from '@style-guide/components/DatePicker';
import moment from 'moment';

import {
  eBarSCOAnnouncementsCreate,
  eBarSCOAnnouncementsUpdate,
  eBarSCOAnnouncementsDelete,
} from '@services/EBarSCOAnnouncements';

import LoadingOverlay from '@components/LoadingOverlay';
import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import { Provider } from 'react-redux';
import Button from '@style-guide/components/Button';
import Store from '@redux';
import PopUpModal from './components/PopUpModal/PopUpModal';
import SCOAnnouncementsForm from './components/SCOAnnouncementsForm/SCOAnnouncementsForm';

import BlockWrapper from './components/BlockWrapper';
import { AnnouncementsTable, ColumnHeaders } from './components/AnnouncementsTable';
import EBarSCOAnnouncementsResultDiv from './components/EBarSCOAnnouncementsResultDiv';
import EBarSCOAnnouncementsResult from './components/EBarSCOAnnouncementsResult/EBarSCOAnnouncementsResult';
import ButtonWrapper from './components/ButtonWrapper';
import DatePickerWrapper from './components/DatePickerWrapper';
import SearchDiv from './components/SearchDiv';
import HeaderDiv from './components/HeaderDiv';
import Title from './components/Title';
import Wrapper from './components/Wrapper';
import ModalBody from './components/ModalBody';
import MessageModalBody from './components/MessageModalBody';


/**
# EBar SCOAnnouncementsCTA
A CTA containing list of links to EBar
Supreme Court of Ohio Case Announcements/Administrative Actions/Orders and Ohio Rules.
 */
class EBarSCOAnnouncementsCTA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      eBarSCOAnnouncementsListsState: this.props.eBarSCOAnnouncementsList,
      create: false,
      edit: false,
      viewId: '',
      formValues: undefined,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
      startDateState: moment(this.props.startDate),
      endDateState: moment(this.props.endDate),
    };

    this.store = Store.get();

    this.onClickSearch = this.onClickSearch.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onClickViewButton = this.onClickViewButton.bind(this);
    this.onClickEditButton = this.onClickEditButton.bind(this);
    this.onClickCreateLink = this.onClickCreateLink.bind(this);
    this.onClickCloseCancel = this.onClickCloseCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
  }

  onClickSearch() {
    this.setState({
      loading: true,
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
    const formattedStartDate = moment(this.state.startDateState).format('MM/DD/YYYY');
    const formattedEndDate = moment(this.state.endDateState).format('MM/DD/YYYY');
    fetch(`/EBarSCOAnnouncementsCTABlock/Search?startDate=${formattedStartDate}&endDate=${formattedEndDate}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then((data) => {
        this.setState({
          viewId: '',
          create: false,
          edit: false,
          eBarSCOAnnouncementsListsState: data,
          formValues: undefined,
          loading: false,
          showPopUp: false,
          deleteGuid: '',
          showMessagePopUp: false,
          messagePopUpTitle: '',
        });
      });
  }

  onChangeStartDate(value) {
    this.setState({
      startDateState: value,
    });
  }

  onChangeEndDate(value) {
    this.setState({
      endDateState: value,
    });
  }

  onClickCreateLink() {
    this.setState({
      viewId: '',
      edit: false,
      formValues: undefined,
      create: true,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onClickViewButton(viewContentId) {
    this.setState({
      viewId: viewContentId,
      create: false,
      edit: false,
      formValues: undefined,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onClickCloseCancel() {
    this.setState({
      viewId: '',
      create: false,
      edit: false,
      formValues: undefined,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onClickEditButton(viewContentId) {
    this.setState({
      viewId: '',
      create: false,
      edit: true,
      showPopUp: false,
      showMessagePopUp: false,
      deleteGuid: '',
      messagePopUpTitle: '',
      formValues: (is.not.null(this.state.eBarSCOAnnouncementsListsState)
          && is.not.undefined(this.state.eBarSCOAnnouncementsListsState)
          && this.state.eBarSCOAnnouncementsListsState.length > 0) ?
        this.state.eBarSCOAnnouncementsListsState.filter(item =>
          item.contentGuidString === viewContentId)[0] :
        undefined,
    });
  }

  onSubmit(data) {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    this.setState({
      viewId: '',
      loading: true,
    });
    const finalPostData = this.functionReplace({ ...data });
    if (this.state.edit) {
      eBarSCOAnnouncementsUpdate(finalPostData)
        .then(() => {
          this.setState({
            viewId: '',
            create: false,
            edit: false,
            formValues: undefined,
            showPopUp: false,
            deleteGuid: '',
            showMessagePopUp: true,
            messagePopUpTitle: 'Supreme Court of Ohio Announcement has been updated successfully',
            loading: false,
          });
        })
        .catch(() => {
          this.setState({
            viewId: '',
            loading: false,
            showMessagePopUp: true,
            messagePopUpTitle: 'Update Supreme Court of Ohio Announcement failed',
          });
        });
    } else if (this.state.create) {
      eBarSCOAnnouncementsCreate(finalPostData)
        .then(() => {
          setTimeout(() => {
            this.setState({
              viewId: '',
              create: false,
              edit: false,
              formValues: undefined,
              startDateState: moment(this.props.startDate),
              endDateState: moment(this.props.endDate),
              showPopUp: false,
              deleteGuid: '',
              showMessagePopUp: true,
              messagePopUpTitle: 'Supreme Court of Ohio Announcement has been created successfully',
              loading: false,
            });
          }, 5000);
        })
        .catch(() => {
          this.setState({
            viewId: '',
            loading: false,
            showMessagePopUp: true,
            messagePopUpTitle: 'Create Supreme Court of Ohio Announcement failed',
          });
        });
    }
  }

  onConfirmDelete(value) {
    this.setState({
      viewId: '',
      showPopUp: false,
      loading: true,
    });

    eBarSCOAnnouncementsDelete(value)
      .then(() => {
        this.setState({
          viewId: '',
          create: false,
          edit: false,
          formValues: undefined,
          showPopUp: false,
          deleteGuid: '',
          showMessagePopUp: true,
          messagePopUpTitle: 'Supreme Court of Ohio Announcement has been deleted successfully',
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          viewId: '',
          loading: false,
          showPopUp: false,
          deleteGuid: '',
          showMessagePopUp: true,
          messagePopUpTitle: 'Delete Supreme Court of Ohio Announcement Failed',
        });
      });
  }

  onClickDelete(value) {
    this.setState({
      create: false,
      edit: false,
      viewId: '',
      formValues: undefined,
      showPopUp: true,
      deleteGuid: value,
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onDeleteCancel() {
    this.setState({
      create: false,
      edit: false,
      viewId: '',
      formValues: undefined,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  /* eslint-disable */

  functionReplace(dataObject) {
    dataObject.summary = this.replaceAngleBrackets(dataObject.summary);
    return dataObject;
  }

  replaceAngleBrackets(summary) {
    if(!summary) {
      return summary;
    }
    summary = summary.replaceAll('<', '&lt;');
    summary = summary.replaceAll('>', '&gt;');
    return summary;
  }

  convertReplaceString(summaryString) {
    if(!summaryString || !summaryString.summary) {
      return summaryString;
    }
    summaryString.summary = summaryString.summary.replaceAll('&lt;', '<');
    summaryString.summary = summaryString.summary.replaceAll('&gt;', '>');
    return summaryString;
  }
  /* eslint-enable */

  render() {
    const {
      loading,
      eBarSCOAnnouncementsListsState,
      formValues,
      edit,
      viewId,
      startDateState,
      endDateState,
      create,
      showPopUp,
      deleteGuid,
      showMessagePopUp,
      messagePopUpTitle,
    } = this.state;

    return (
      <BlockWrapper>
        {loading && <LoadingOverlay />}
        {showPopUp && deleteGuid && !showMessagePopUp && (
        <PopUpModal
          dismissable={false}
          body={(
            <ModalBody
              yesFunction={this.onConfirmDelete}
              noFunction={this.onDeleteCancel}
              deleteGuidString={deleteGuid}
            />
          )}
          closeAction={this.onDeleteCancel}
        />
        )}
        {showMessagePopUp && messagePopUpTitle && !showPopUp && (
          <PopUpModal
            dismissable={false}
            body={(
              <MessageModalBody
                title={messagePopUpTitle}
                okFunction={this.onClickSearch}
              />
            )}
            closeAction={this.onClickSearch}
          />
        )}
        <Wrapper>
          <YellowLineSVG />
          <HeaderDiv>
            <Title>{this.props.heading}</Title>
            <Button
              onClick={() => this.onClickCreateLink()}
              disabled={create}
            >
              Create
            </Button>
          </HeaderDiv>
          {(create || edit) && (
            <Provider store={this.store}>
              <SCOAnnouncementsForm
                initialValues={formValues}
                onSubmit={this.onSubmit}
                onClickCloseCancel={this.onClickCloseCancel}
                edit={edit}
              />
            </Provider>
          )}
          <SearchDiv>
            <DatePickerWrapper>
              <DatePicker
                id="FromDate"
                maxDate={moment().add(31, 'days').toDate()}
                onSelect={value => this.onChangeStartDate(value)}
                scrollableYearDropdown
                selected={moment(startDateState).toDate()}
                showYearDropdown
                yearDropdownItemNumber={50}
                placeholderText="mm/dd/yyyy"
                label="From"
              />
            </DatePickerWrapper>
            <DatePickerWrapper>
              <DatePicker
                id="ToDate"
                maxDate={moment().add(31, 'days').toDate()}
                onSelect={value => this.onChangeEndDate(value)}
                scrollableYearDropdown
                selected={moment(endDateState).toDate()}
                showYearDropdown
                yearDropdownItemNumber={50}
                placeholderText="mm/dd/yyyy"
                label="To"
              />
            </DatePickerWrapper>
            <ButtonWrapper>
              <Button
                onClick={() => this.onClickSearch()}
              >
                Search
              </Button>
            </ButtonWrapper>
          </SearchDiv>
          {is.not.null(eBarSCOAnnouncementsListsState) &&
            eBarSCOAnnouncementsListsState.length > 0 && (
            <EBarSCOAnnouncementsResultDiv>
              <AnnouncementsTable>
                <thead>
                  <ColumnHeaders>
                    <th><h3>SUB HEADER</h3></th>
                    <th><h3>TITLE</h3></th>
                    <th><h3>CITE</h3></th>
                    <th><h3>DATE TYPE</h3></th>
                    <th><h3>DATE</h3></th>
                    <th><h3>AUTHOR</h3></th>
                    <th><h3>ACTIVE DATE</h3></th>
                    <th><h3>ACTIONS</h3></th>
                  </ColumnHeaders>
                </thead>
                <tbody>
                  {eBarSCOAnnouncementsListsState.map(c => (
                    <EBarSCOAnnouncementsResult
                      key={c.contentGuidString}
                      initialValues={this.convertReplaceString(c)}
                      viewId={viewId}
                      onClickViewButton={this.onClickViewButton}
                      onClickEditButton={this.onClickEditButton}
                      onClickCloseCancel={this.onClickCloseCancel}
                      onClickDelete={this.onClickDelete}
                    />
                  ))}
                </tbody>
              </AnnouncementsTable>
            </EBarSCOAnnouncementsResultDiv>
          )}
        </Wrapper>
      </BlockWrapper>
    );
  }
}

EBarSCOAnnouncementsCTA.propTypes = {
  eBarSCOAnnouncementsList: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    subHeaderString: PropTypes.string,
    title: PropTypes.string,
    cite: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    effectivePostedDateString: PropTypes.string,
    dateTypeString: PropTypes.string,
    summary: PropTypes.string,
    createdDate: PropTypes.string,
    activeDateString: PropTypes.string,
    modifiedBy: PropTypes.string,
  })).isRequired,
  heading: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

EBarSCOAnnouncementsCTA.defaultProps = {
};


EBarSCOAnnouncementsCTA.displayName = 'EBarSCOAnnouncementsCTA';

export default EBarSCOAnnouncementsCTA;
