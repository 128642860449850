import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from '@components/LoadingOverlay';
import sortBy from 'lodash/sortBy';
import {
  ContentArea,
  Wrapper,
} from '@components/SearchResults';
import moment from 'moment';
import SearchFilterLeftRail from '@structures/SearchFilterLeftRail';
import Results from './components/Results';
import ResourceTypeFilter from './components/ResourceTypeFilter';
import DateRangeFilter from './components/DateRangeFilter';
import KeywordFilter from './components/KeywordFilter';
import PracticeAreaFilter from './components/PracticeAreaFilter';
import CourtsFilter from './components/CourtsFilter';

class EBarSearchPage extends React.Component {
  constructor(props) {
    super(props);

    const filterGroup = (opts, startCharCode, endCharCode) => {
      let filteredOpts = [];
      if (opts != null) {
        filteredOpts = opts.filter((practiceArea) => {
          const optCharCode = practiceArea.name.charCodeAt(0);

          return optCharCode >= startCharCode && optCharCode <= endCharCode;
        });
      }

      const orderedOpts = sortBy(filteredOpts, ['name']);

      return orderedOpts;
    };

    this.state = {
      loading: false,
      eBarCaseUpdate: this.props.eBarCase,
      eBarCase: this.props.eBarCase,
      eBarLegislativeEnactment: this.props.eBarLegislativeEnactment,
      eBarSCOAnnouncement: this.props.eBarSCOAnnouncement,
      eBarBoardOfProfessionalsConduct: this.props.eBarBoardOfProfessionalsConduct,
      eBarEthicsCommissionAdvisoryOpinion: this.props.eBarEthicsCommissionAdvisoryOpinion,
      eBarAttorneyGeneralOpinion: this.props.eBarAttorneyGeneralOpinion,
      eBarOtherAnnouncement: this.props.eBarOtherAnnouncement,
      newEBarCase: this.props.eBarCase.slice(0, 5),
      newEBarLegislativeEnactment: this.props.eBarLegislativeEnactment.slice(0, 5),
      newEBarSCOAnnouncement: this.props.eBarSCOAnnouncement.slice(0, 5),
      newEBarBoardOfProfessionalsConduct: this.props.eBarBoardOfProfessionalsConduct.slice(0, 5),
      newEBarEthicsCommissionAdvisoryOpinion:
        this.props.eBarEthicsCommissionAdvisoryOpinion.slice(0, 5),
      newEBarAttorneyGeneralOpinion: this.props.eBarAttorneyGeneralOpinion.slice(0, 5),
      newEBarOtherAnnouncement: this.props.eBarOtherAnnouncement.slice(0, 5),
      count: {
        case: 1,
        enactment: 1,
        sco: 1,
        conduct: 1,
        ethics: 1,
        attorney: 1,
        other: 1,
      },
      totalCount: {
        case: this.props.caseCount,
        enactment: this.props.enactmentCount,
        sco: this.props.scoCount,
        conduct: this.props.conductCount,
        ethics: this.props.ethicsCount,
        attorney: this.props.attorneyCount,
        other: this.props.otherCount,
      },
      areasOfLaw: this.props.areasOfLaw.map(item => ({
        ...item,
        itemState: false,
      })),
      groups: {
        AD: filterGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 65, 68),
        EK: filterGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 69, 75),
        LR: filterGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 76, 82),
        SZ: filterGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 83, 90),
      },
      courts: this.props.courts.map(item => ({
        ...item,
        itemState: false,
      })),
      keywordValue: this.props.keywordValue,
      startDate: null,
      endDate: null,
      showAllResults: true,
      showResults: {
        Case: false,
        Enactment: false,
        SCO: false,
        Conduct: false,
        Ethics: false,
        Attorney: false,
        Other: false,
      },
      sortBy: this.props.keywordValue === '' ? 'mostRecent' : 'relevancy',
      filterValues: [
        {
          FilterId: '1',
          FilterName: 'Keyword',
          FilterValue: this.props.keywordValue != null ? this.props.keywordValue.length > 0 : false,
        },
        { FilterId: '2', FilterName: 'Date Range', FilterValue: false },
        { FilterId: '3', FilterName: 'Content Type', FilterValue: false },
        { FilterId: '4', FilterName: 'Areas of Law', FilterValue: false },
        { FilterId: '5', FilterName: 'Courts', FilterValue: false },
      ],
    };

    this.pageCountUpdate = this.pageCountUpdate.bind(this);
    this.filterAppendOrRemove = this.filterAppendOrRemove.bind(this);
    this.filterClearAll = this.filterClearAll.bind(this);
    this.keywordUpdate = this.keywordUpdate.bind(this);
    this.keywordFilterUpdate = this.keywordFilterUpdate.bind(this);
    this.keywordFilterRemove = this.keywordFilterRemove.bind(this);
    this.dateFilterUpdate = this.dateFilterUpdate.bind(this);
    this.filterByAreasOfLaw = this.filterByAreasOfLaw.bind(this);
    this.filterByCourts = this.filterByCourts.bind(this);
    this.updateAreasOfLaw = this.updateAreasOfLaw.bind(this);
    this.filterAoLGroup = this.filterAoLGroup.bind(this);
    this.filterAoLClearAll = this.filterAoLClearAll.bind(this);
    this.updateCourts = this.updateCourts.bind(this);
    this.filterCourtsClearAll = this.filterCourtsClearAll.bind(this);
    this.updateStartDate = this.updateStartDate.bind(this);
    this.updateEndDate = this.updateEndDate.bind(this);
    this.sortUpdate = this.sortUpdate.bind(this);
    this.sortEBarCase = this.sortEBarCase.bind(this);
    this.updateSearchFilter = this.updateSearchFilter.bind(this);
    this.clearSearchFilters = this.clearSearchFilters.bind(this);
    this.updateDataByFilters = this.updateDataByFilters.bind(this);
    this.dataUpdate = this.dataUpdate.bind(this);
  }

  componentDidMount() {
    if (this.state.keywordValue === '') {
      this.sortUpdate('mostRecent');
    } else {
      this.sortUpdate('relevancy');
    }
  }

  pageCountUpdate(type) {
    this.setState({
      loading: true,
    });

    if (type === 'case') {
      const Count = this.state.count.case + 1;
      this.setState({
        count: { ...this.state.count, case: Count },
        newEBarCase: this.state.eBarCase.slice(0, 5 * Count),
      });
    } else if (type === 'enactment') {
      const Count = this.state.count.enactment + 1;
      this.setState({
        count: { ...this.state.count, enactment: Count },
        newEBarLegislativeEnactment: this.state.eBarLegislativeEnactment.slice(0, 5 * Count),
      });
    } else if (type === 'sco') {
      const Count = this.state.count.sco + 1;
      this.setState({
        count: { ...this.state.count, sco: Count },
        newEBarSCOAnnouncement: this.state.eBarSCOAnnouncement.slice(0, 5 * Count),
      });
    } else if (type === 'conduct') {
      const Count = this.state.count.conduct + 1;
      this.setState({
        count: { ...this.state.count, conduct: Count },
        newEBarBoardOfProfessionalsConduct:
          this.state.eBarBoardOfProfessionalsConduct.slice(0, 5 * Count),
      });
    } else if (type === 'ethics') {
      const Count = this.state.count.ethics + 1;
      this.setState({
        count: { ...this.state.count, ethics: Count },
        newEBarEthicsCommissionAdvisoryOpinion:
          this.state.eBarEthicsCommissionAdvisoryOpinion.slice(0, 5 * Count),
      });
    } else if (type === 'attorney') {
      const Count = this.state.attorneyCount + 1;
      this.setState({
        count: { ...this.state.count, attorney: Count },
        newEBarAttorneyGeneralOpinion: this.state.eBarAttorneyGeneralOpinion.slice(0, 5 * Count),
      });
    } else if (type === 'other') {
      const Count = this.state.count.other + 1;
      this.setState({
        count: { ...this.state.count, other: Count },
        newEBarOtherAnnouncement: this.state.eBarOtherAnnouncement.slice(0, 5 * Count),
      });
    }
    setTimeout(() => this.dataUpdate(), 1000);
  }

  filterAppendOrRemove(filterId) {
    const ShowResults = { ...this.state.showResults };
    switch (filterId) {
      case 1:
        ShowResults.Case = !ShowResults.Case;
        break;
      case 2:
        ShowResults.Enactment = !ShowResults.Enactment;
        break;
      case 3:
        ShowResults.SCO = !ShowResults.SCO;
        break;
      case 4:
        ShowResults.Conduct = !ShowResults.Conduct;
        break;
      case 5:
        ShowResults.Ethics = !ShowResults.Ethics;
        break;
      case 6:
        ShowResults.Attorney = !ShowResults.Attorney;
        break;
      case 7:
        ShowResults.Other = !ShowResults.Other;
        break;
      default:
        break;
    }

    this.setState({
      showResults: {
        Case: ShowResults.Case,
        Enactment: ShowResults.Enactment,
        SCO: ShowResults.SCO,
        Conduct: ShowResults.Conduct,
        Ethics: ShowResults.Ethics,
        Attorney: ShowResults.Attorney,
        Other: ShowResults.Other,
      },
    });

    let filterValues = JSON.parse(JSON.stringify(this.state.filterValues));

    if (!ShowResults.Case) {
      this.setState({
        eBarCase: this.state.eBarCaseUpdate,
        newEBarCase: this.state.eBarCaseUpdate.slice(0, 5),
        areasOfLaw: this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })),
        groups: {
          AD: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
            ...item,
            itemState: false,
          })), 65, 68),
          EK: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
            ...item,
            itemState: false,
          })), 69, 75),
          LR: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
            ...item,
            itemState: false,
          })), 76, 82),
          SZ: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
            ...item,
            itemState: false,
          })), 83, 90),
        },
        courts: this.props.courts.map(item => ({
          ...item,
          itemState: false,
        })),
      });

      /* eslint-disable */
      filterValues = filterValues.map(
        el => el.FilterName === 'Areas of Law' ? { ...el, FilterValue: false } : el,
      );
      filterValues = filterValues.map(
        el => el.FilterName === 'Courts'? { ...el, FilterValue: false }: el
      );
      /* eslint-enable */
    }

    if (!ShowResults.Case && !ShowResults.Enactment && !ShowResults.SCO && !ShowResults.Conduct
      && !ShowResults.Ethics && !ShowResults.Attorney && !ShowResults.Other) {
      this.setState({
        showAllResults: true,
      });

      /* eslint-disable */
      filterValues = filterValues.map(
        el => el.FilterName === 'Content Type' ? { ...el, FilterValue: false } : el,
      );
      /* eslint-enable */
    } else {
      this.setState({
        showAllResults: false,
      });

      /* eslint-disable */
      filterValues = filterValues.map(
        el => el.FilterName === 'Content Type' ? { ...el, FilterValue: true } : el,
      );
    }

    this.setState({
      filterValues: filterValues,
    });
    /* eslint-enable */
  }

  filterClearAll() {
    this.setState({
      showAllResults: true,
      showResults: {
        Case: false,
        Enactment: false,
        SCO: false,
        Conduct: false,
        Ethics: false,
        Attorney: false,
        Other: false,
      },
      areasOfLaw: this.props.areasOfLaw.map(item => ({
        ...item,
        itemState: false,
      })),
      groups: {
        AD: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 65, 68),
        EK: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 69, 75),
        LR: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 76, 82),
        SZ: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 83, 90),
      },
      courts: this.props.courts.map(item => ({
        ...item,
        itemState: false,
      })),
      eBarCase: this.state.eBarCaseUpdate,
      newEBarCase: this.state.eBarCaseUpdate.slice(0, 5),
      /* eslint-disable */
      filterValues: this.state.filterValues.map(item => ({
        ...item,
        FilterValue: false,
      })),
      /* eslint-enable */
    });

    setTimeout(() => this.dataUpdate(), 1000);
  }

  keywordUpdate(keyword) {
    this.setState({
      keywordValue: keyword,
    });
  }

  /* eslint-disable */
  keywordFilterUpdate(filterField, keyword) {
    const aol = this.state.areasOfLaw.filter(
      item => item.itemState === true,
    );
    this.setState({
      loading: true,
    });
    fetch(`/EBarSearchPage/Search?AoL=${JSON.stringify(aol)}&courts=${JSON.stringify(this.state.courts)}&count=${JSON.stringify(this.state.count)}&sortBy=${this.state.sortBy}&searchData=${this.state.keywordValue}&start=${this.state.startDate}&end=${this.state.endDate}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then((data) => {
        this.setState({
          loading: false,
          eBarCaseUpdate: data.EBarCases,
          eBarCase: data.EBarCases,
          eBarLegislativeEnactment: data.EBarLegislativeEnactment,
          eBarSCOAnnouncement: data.EBarSCOAnnouncement,
          eBarBoardOfProfessionalsConduct: data.EBarBoardOfProfessionalsConduct,
          eBarEthicsCommissionAdvisoryOpinion: data.EBarEthicsCommissionAdvisoryOpinion,
          eBarAttorneyGeneralOpinion: data.EBarAttorneyGeneralOpinion,
          eBarOtherAnnouncement: data.EBarOtherAnnouncement,
          count: {
            case: 1,
            enactment: 1,
            sco: 1,
            conduct: 1,
            ethics: 1,
            attorney: 1,
            other: 1,
          },
          totalCount: {
            case: data.caseCount,
            enactment: data.enactmentCount,
            sco: data.scoCount,
            conduct: data.conductCount,
            ethics: data.ethicsCount,
            attorney: data.attorneyCount,
            other: data.otherCount,
          },
          filterValues: this.state.filterValues.map(
            el => el.FilterName === 'Keyword' ? { ...el, FilterValue: this.state.keywordValue.length > 0 } : el,
          ),
        });
        this.setState({
          newEBarCase: this.state.eBarCase.slice(0, 5),
          newEBarLegislativeEnactment: this.state.eBarLegislativeEnactment.slice(0, 5),
          newEBarSCOAnnouncement: this.state.eBarSCOAnnouncement.slice(0, 5),
          newEBarBoardOfProfessionalsConduct:
            this.state.eBarBoardOfProfessionalsConduct.slice(0, 5),
          newEBarEthicsCommissionAdvisoryOpinion:
            this.state.eBarEthicsCommissionAdvisoryOpinion.slice(0, 5),
          newEBarAttorneyGeneralOpinion: this.state.eBarAttorneyGeneralOpinion.slice(0, 5),
          newEBarOtherAnnouncement: this.state.eBarOtherAnnouncement.slice(0, 5),
        });
        this.updateDataByFilters();
        if (this.state.keywordValue === '') {
          this.sortUpdate('mostRecent');
        } else {
          this.sortUpdate('relevancy');
        }
      });
  }

  keywordFilterRemove(filterField, keyword) {
    const aol = this.state.areasOfLaw.filter(
      item => item.itemState === true,
    );
    this.setState({
      loading: true,
    });
    fetch(`/EBarSearchPage/Search?AoL=${JSON.stringify(aol)}&courts=${JSON.stringify(this.state.courts)}&count=${JSON.stringify(this.state.count)}&sortBy=${this.state.sortBy}&start=${this.state.startDate}&end=${this.state.endDate}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then((data) => {
        this.setState({
          loading: false,
          eBarCaseUpdate: data.EBarCases,
          eBarCase: data.EBarCases,
          eBarLegislativeEnactment: data.EBarLegislativeEnactment,
          eBarSCOAnnouncement: data.EBarSCOAnnouncement,
          eBarBoardOfProfessionalsConduct: data.EBarBoardOfProfessionalsConduct,
          eBarEthicsCommissionAdvisoryOpinion: data.EBarEthicsCommissionAdvisoryOpinion,
          eBarAttorneyGeneralOpinion: data.EBarAttorneyGeneralOpinion,
          eBarOtherAnnouncement: data.EBarOtherAnnouncement,
          count: {
            case: 1,
            enactment: 1,
            sco: 1,
            conduct: 1,
            ethics: 1,
            attorney: 1,
            other: 1,
          },
          totalCount: {
            case: data.caseCount,
            enactment: data.enactmentCount,
            sco: data.scoCount,
            conduct: data.conductCount,
            ethics: data.ethicsCount,
            attorney: data.attorneyCount,
            other: data.otherCount,
          },
          filterValues: this.state.filterValues.map(
            el => el.FilterName === 'Keyword' ? { ...el, FilterValue: this.state.keywordValue.length > 0 } : el,
          ),
        });
        this.setState({
          newEBarCase: this.state.eBarCase.slice(0, 5),
          newEBarLegislativeEnactment: this.state.eBarLegislativeEnactment.slice(0, 5),
          newEBarSCOAnnouncement: this.state.eBarSCOAnnouncement.slice(0, 5),
          newEBarBoardOfProfessionalsConduct:
            this.state.eBarBoardOfProfessionalsConduct.slice(0, 5),
          newEBarEthicsCommissionAdvisoryOpinion:
            this.state.eBarEthicsCommissionAdvisoryOpinion.slice(0, 5),
          newEBarAttorneyGeneralOpinion: this.state.eBarAttorneyGeneralOpinion.slice(0, 5),
          newEBarOtherAnnouncement: this.state.eBarOtherAnnouncement.slice(0, 5),
        });
        this.updateDataByFilters();
        if (this.state.keywordValue === '') {
          this.sortUpdate('mostRecent');
        } else {
          this.sortUpdate('relevancy');
        }
      });
  }
  /* eslint-enable */

  dateFilterUpdate(start, end) {
    const aol = this.state.areasOfLaw.filter(
      item => item.itemState === true,
    );
    this.setState({
      loading: true,
      startDate: start,
      endDate: end,
    });
    fetch(`/EBarSearchPage/Search?AoL=${JSON.stringify(aol)}&courts=${JSON.stringify(this.state.courts)}&count=${JSON.stringify(this.state.count)}&sortBy=${this.state.sortBy}&searchData=${this.state.keywordValue}&start=${start}&end=${end}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then((data) => {
        this.setState({
          loading: false,
          eBarCaseUpdate: data.EBarCases,
          eBarCase: data.EBarCases,
          eBarLegislativeEnactment: data.EBarLegislativeEnactment,
          eBarSCOAnnouncement: data.EBarSCOAnnouncement,
          eBarBoardOfProfessionalsConduct: data.EBarBoardOfProfessionalsConduct,
          eBarEthicsCommissionAdvisoryOpinion: data.EBarEthicsCommissionAdvisoryOpinion,
          eBarAttorneyGeneralOpinion: data.EBarAttorneyGeneralOpinion,
          eBarOtherAnnouncement: data.EBarOtherAnnouncement,
          count: {
            case: 1,
            enactment: 1,
            sco: 1,
            conduct: 1,
            ethics: 1,
            attorney: 1,
            other: 1,
          },
          totalCount: {
            case: data.caseCount,
            enactment: data.enactmentCount,
            sco: data.scoCount,
            conduct: data.conductCount,
            ethics: data.ethicsCount,
            attorney: data.attorneyCount,
            other: data.otherCount,
          },
          /* eslint-disable */
          filterValues: this.state.filterValues.map(
            el => el.FilterName === 'Date Range' ? { ...el, FilterValue: true } : el,
          ),
          /* eslint-enable */
        });
        this.setState({
          newEBarCase: this.state.eBarCase.slice(0, 5),
          newEBarLegislativeEnactment: this.state.eBarLegislativeEnactment.slice(0, 5),
          newEBarSCOAnnouncement: this.state.eBarSCOAnnouncement.slice(0, 5),
          newEBarBoardOfProfessionalsConduct:
            this.state.eBarBoardOfProfessionalsConduct.slice(0, 5),
          newEBarEthicsCommissionAdvisoryOpinion:
            this.state.eBarEthicsCommissionAdvisoryOpinion.slice(0, 5),
          newEBarAttorneyGeneralOpinion: this.state.eBarAttorneyGeneralOpinion.slice(0, 5),
          newEBarOtherAnnouncement: this.state.eBarOtherAnnouncement.slice(0, 5),
        });
        this.updateDataByFilters();
        if (this.state.keywordValue === '') {
          this.sortUpdate('mostRecent');
        } else {
          this.sortUpdate('relevancy');
        }
      });
  }

  updateStartDate(start) {
    this.setState({
      startDate: start,
    });
  }

  updateEndDate(end) {
    this.setState({
      endDate: end,
    });
  }

  /* eslint-disable */
  filterAoLGroup(opts, startCharCode, endCharCode) {
    let filteredOpts = [];
    if (opts != null) {
      filteredOpts = opts.filter((practiceArea) => {
        const optCharCode = practiceArea.name.charCodeAt(0);

        return optCharCode >= startCharCode && optCharCode <= endCharCode;
      });
    }

    const orderedOpts = sortBy(filteredOpts, ['name']);

    return orderedOpts;
  }

  filterByAreasOfLaw(arr1, arr2) {
    let res = [];
    res = Object.values(arr1).filter((el) => {
      return arr2.find((element) => {
        return element.id === el.practiceId;
      });
    });
    return res;
  }

  filterByCourts(arr1, arr2) {
    let res = [];
    res = Object.values(arr1).filter((el) => {
      return arr2.find((element) => {
        return element.id === el.courtId;
      });
    });
    return res;
  }

  updateAreasOfLaw(areaId, state) {
    let areasOfLaw = JSON.parse(JSON.stringify(this.state.areasOfLaw));
    let eBarCase = JSON.parse(JSON.stringify(this.state.eBarCaseUpdate));
    let filtered;
    areasOfLaw = areasOfLaw.map(
      el => el.id === areaId? { ...el, itemState: state }: el
    );
    this.setState({
      areasOfLaw: areasOfLaw,
    });
    
    this.setState({
      groups: {
        AD: this.filterAoLGroup(areasOfLaw, 65, 68),
        EK: this.filterAoLGroup(areasOfLaw, 69, 75),
        LR: this.filterAoLGroup(areasOfLaw, 76, 82),
        SZ: this.filterAoLGroup(areasOfLaw, 83, 90),
      },
    });

    areasOfLaw = areasOfLaw.filter(practiceArea => (practiceArea.itemState === true));
    if (Object.keys(areasOfLaw).length > 0) {
      this.setState({
        filterValues: this.state.filterValues.map(
          el => el.FilterName === 'Areas of Law' ? { ...el, FilterValue: true } : el,
        ),
      });
    } else {
      filtered = this.state.eBarCaseUpdate;
      this.setState({
        filterValues: this.state.filterValues.map(
          el => el.FilterName === 'Areas of Law' ? { ...el, FilterValue: false } : el,
        ),
      });
    }

    setTimeout(() => this.dataUpdate(), 1000);
  }
  /* eslint-enable */

  filterAoLClearAll() {
    this.setState({
      eBarCase: this.state.eBarCaseUpdate,
      newEBarCase: this.state.eBarCaseUpdate.slice(0, 5),
      areasOfLaw: this.props.areasOfLaw.map(item => ({
        ...item,
        itemState: false,
      })),
      groups: {
        AD: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 65, 68),
        EK: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 69, 75),
        LR: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 76, 82),
        SZ: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 83, 90),
      },
      /* eslint-disable */
      filterValues: this.state.filterValues.map(
        el => el.FilterName === 'Areas of Law' ? { ...el, FilterValue: false } : el,
      ),
      /* eslint-enable */
    });

    setTimeout(() => this.dataUpdate(), 1000);
  }

  /* eslint-disable */
  updateCourts(courtId, state) {
    let courts = JSON.parse(JSON.stringify(this.state.courts));
    let eBarCase = JSON.parse(JSON.stringify(this.state.eBarCaseUpdate));
    let filtered;
    courts = courts.map(
      el => el.id === courtId ? { ...el, itemState: state } : el,
    );
    this.setState({
      courts: courts,
    });

    courts = courts.filter(court => (court.itemState === true));

    if (Object.keys(courts).length > 0) {
      filtered = this.filterByCourts(eBarCase, courts);

      this.setState({
        filterValues: this.state.filterValues.map(
          el => el.FilterName === 'Courts'? { ...el, FilterValue: true }: el
        ),
      });
    } else {
      this.setState({
        filterValues: this.state.filterValues.map(
          el => el.FilterName === 'Courts'? { ...el, FilterValue: false }: el
        ),
      });
    }

    setTimeout(() => this.dataUpdate(), 1000);
  }

  filterCourtsClearAll() {
    this.setState({
      eBarCase: this.state.eBarCaseUpdate,
      newEBarCase: this.state.eBarCaseUpdate.slice(0, 5),
      courts: this.props.courts.map(item => ({
        ...item,
        itemState: false,
      })),
      filterValues: this.state.filterValues.map(
          el => el.FilterName === 'Courts'? { ...el, FilterValue: false }: el
      ),
    });

    setTimeout(() => this.dataUpdate(), 1000);
  }

  sortEBarCase() {
    let res = [];
    res = Object.values(this.state.eBarCaseUpdate).filter((el) => {
      return this.state.eBarCase.find((element) => {
        return element.contentGuidString === el.contentGuidString;
      });
    });
    return res;
  }
  /* eslint-enable */

  sortUpdate(criteria) {
    if (criteria === 'relevancy') {
      this.setState({
        sortBy: criteria,
      });
      setTimeout(() => this.dataUpdate(), 1000);
    } else if (criteria === 'mostRecent') {
      this.setState({
        sortBy: criteria,
      });
      setTimeout(() => this.dataUpdate(), 1000);
    } else if (criteria === 'nameAZ') {
      this.setState({
        sortBy: criteria,
      });
      setTimeout(() => this.dataUpdate(), 1000);
    } else if (criteria === 'nameZA') {
      this.setState({
        sortBy: criteria,
      });
      setTimeout(() => this.dataUpdate(), 1000);
    }

    this.setState({
      newEBarCase: this.state.eBarCase.slice(0, 5),
      newEBarLegislativeEnactment: this.state.eBarLegislativeEnactment.slice(0, 5),
      newEBarSCOAnnouncement: this.state.eBarSCOAnnouncement.slice(0, 5),
      newEBarBoardOfProfessionalsConduct:
        this.state.eBarBoardOfProfessionalsConduct.slice(0, 5),
      newEBarEthicsCommissionAdvisoryOpinion:
        this.state.eBarEthicsCommissionAdvisoryOpinion.slice(0, 5),
      newEBarAttorneyGeneralOpinion: this.state.eBarAttorneyGeneralOpinion.slice(0, 5),
      newEBarOtherAnnouncement: this.state.eBarOtherAnnouncement.slice(0, 5),
    });
  }

  /* eslint-disable */
  updateSearchFilter(filterName, filterValue) {
    this.setState({
      filterValues: this.state.filterValues.map(
        el => el.FilterName === filterName ? { ...el, FilterValue: filterValue } : el,
      ),
    });
    if (filterName === 'Keyword') {
      this.keywordUpdate('');
      setTimeout(() => this.dataUpdate(), 1000);
    } else if (filterName === 'Date Range') {
      this.setState({
        startDate: null,
        endDate: null,
      });
      setTimeout(() => this.dataUpdate(), 1000);
    } else if (filterName === 'Content Type') {
      this.filterClearAll();
    } else if (filterName === 'Areas of Law') {
      this.filterAoLClearAll();
    } else if (filterName === 'Courts') {
      this.filterCourtsClearAll();
    }
  }
  /* eslint-enable */

  clearSearchFilters() {
    this.setState({
      filterValues: this.state.filterValues.map(item => ({
        ...item,
        FilterValue: false,
      })),
      startDate: null,
      endDate: null,
      showAllResults: true,
      showResults: {
        Case: false,
        Enactment: false,
        SCO: false,
        Conduct: false,
        Ethics: false,
        Attorney: false,
        Other: false,
      },
      areasOfLaw: this.props.areasOfLaw.map(item => ({
        ...item,
        itemState: false,
      })),
      groups: {
        AD: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 65, 68),
        EK: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 69, 75),
        LR: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 76, 82),
        SZ: this.filterAoLGroup(this.props.areasOfLaw.map(item => ({
          ...item,
          itemState: false,
        })), 83, 90),
      },
      courts: this.props.courts.map(item => ({
        ...item,
        itemState: false,
      })),
    });
    this.keywordUpdate('');
    this.setState({
      loading: true,
    });
    setTimeout(() => console.log(''), 1000);
    const aol = this.state.areasOfLaw.filter(
      item => item.itemState === true,
    );
    fetch(`/EBarSearchPage/Search?AoL=${JSON.stringify(aol)}&courts=${JSON.stringify(this.state.courts)}&count=${JSON.stringify(this.state.count)}&sortBy=${this.state.sortBy}&searchData=${this.state.keywordValue}&start=${this.state.startDate}&end=${this.state.endDate}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then((data) => {
        this.setState({
          loading: false,
          eBarCaseUpdate: data.EBarCases,
          eBarCase: data.EBarCases,
          eBarLegislativeEnactment: data.EBarLegislativeEnactment,
          eBarSCOAnnouncement: data.EBarSCOAnnouncement,
          eBarBoardOfProfessionalsConduct: data.EBarBoardOfProfessionalsConduct,
          eBarEthicsCommissionAdvisoryOpinion: data.EBarEthicsCommissionAdvisoryOpinion,
          eBarAttorneyGeneralOpinion: data.EBarAttorneyGeneralOpinion,
          eBarOtherAnnouncement: data.EBarOtherAnnouncement,
          count: {
            case: 1,
            enactment: 1,
            sco: 1,
            conduct: 1,
            ethics: 1,
            attorney: 1,
            other: 1,
          },
          totalCount: {
            case: data.caseCount,
            enactment: data.enactmentCount,
            sco: data.scoCount,
            conduct: data.conductCount,
            ethics: data.ethicsCount,
            attorney: data.attorneyCount,
            other: data.otherCount,
          },
        });
        this.setState({
          newEBarCase: this.state.eBarCase.slice(0, 5),
          newEBarLegislativeEnactment: this.state.eBarLegislativeEnactment.slice(0, 5),
          newEBarSCOAnnouncement: this.state.eBarSCOAnnouncement.slice(0, 5),
          newEBarBoardOfProfessionalsConduct:
            this.state.eBarBoardOfProfessionalsConduct.slice(0, 5),
          newEBarEthicsCommissionAdvisoryOpinion:
            this.state.eBarEthicsCommissionAdvisoryOpinion.slice(0, 5),
          newEBarAttorneyGeneralOpinion: this.state.eBarAttorneyGeneralOpinion.slice(0, 5),
          newEBarOtherAnnouncement: this.state.eBarOtherAnnouncement.slice(0, 5),
        });

        if (this.state.keywordValue === '') {
          this.sortUpdate('mostRecent');
        } else {
          this.sortUpdate('relevancy');
        }
      });
  }

  updateDataByFilters() {
    const ShowResults = { ...this.state.showResults };
    if (!ShowResults.Case && !ShowResults.Enactment && !ShowResults.SCO && !ShowResults.Conduct
      && !ShowResults.Ethics && !ShowResults.Attorney && !ShowResults.Other) {
      this.setState({
        showAllResults: true,
        /* eslint-disable */
        filterValues: this.state.filterValues.map(
          el => el.FilterName === 'Content Type' ? { ...el, FilterValue: false } : el,
        ),
        /* eslint-enable */
      });
    } else {
      this.setState({
        showAllResults: false,
        /* eslint-disable */
        filterValues: this.state.filterValues.map(
          el => el.FilterName === 'Content Type' ? { ...el, FilterValue: true } : el,
        ),
        /* eslint-enable */
      });
    }

    let filtered = { ...this.state.eBarCaseUpdate };

    const areasOfLaw = this.state.areasOfLaw.filter(area => (area.itemState === true));
    if (Object.keys(areasOfLaw).length > 0) {
      filtered = this.filterByAreasOfLaw(filtered, areasOfLaw);
      this.setState({
        eBarCase: filtered,
        newEBarCase: filtered.length > 5 ? filtered.slice(0, 5) : filtered,
      });
    }

    const courts = this.state.courts.filter(court => (court.itemState === true));
    if (Object.keys(courts).length > 0) {
      filtered = this.filterByCourts(filtered, courts);
      this.setState({
        eBarCase: filtered,
        newEBarCase: filtered.length > 5 ? filtered.slice(0, 5) : filtered,
      });
    }
  }

  dataUpdate() {
    const aol = this.state.areasOfLaw.filter(
      item => item.itemState === true,
    );
    this.setState({
      loading: true,
    });
    fetch(`/EBarSearchPage/Search?AoL=${JSON.stringify(aol)}&courts=${JSON.stringify(this.state.courts)}&count=${JSON.stringify(this.state.count)}&sortBy=${this.state.sortBy}&searchData=${this.state.keywordValue}&start=${this.state.startDate}&end=${this.state.endDate}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then((data) => {
        this.setState({
          loading: false,
          eBarCaseUpdate: data.EBarCases,
          eBarCase: data.EBarCases,
          eBarLegislativeEnactment: data.EBarLegislativeEnactment,
          eBarSCOAnnouncement: data.EBarSCOAnnouncement,
          eBarBoardOfProfessionalsConduct: data.EBarBoardOfProfessionalsConduct,
          eBarEthicsCommissionAdvisoryOpinion: data.EBarEthicsCommissionAdvisoryOpinion,
          eBarAttorneyGeneralOpinion: data.EBarAttorneyGeneralOpinion,
          eBarOtherAnnouncement: data.EBarOtherAnnouncement,
          totalCount: {
            case: data.caseCount,
            enactment: data.enactmentCount,
            sco: data.scoCount,
            conduct: data.conductCount,
            ethics: data.ethicsCount,
            attorney: data.attorneyCount,
            other: data.otherCount,
          },
          /* eslint-disable */
          filterValues: this.state.filterValues.map(
            el => el.FilterName === 'Keyword' ? { ...el, FilterValue: this.state.keywordValue.length > 0 } : el,
          ),
          /* eslint-enable */
        });
        this.setState({
          newEBarCase: this.state.eBarCase.slice(0, 5),
          newEBarLegislativeEnactment: this.state.eBarLegislativeEnactment.slice(0, 5),
          newEBarSCOAnnouncement: this.state.eBarSCOAnnouncement.slice(0, 5),
          newEBarBoardOfProfessionalsConduct:
            this.state.eBarBoardOfProfessionalsConduct.slice(0, 5),
          newEBarEthicsCommissionAdvisoryOpinion:
            this.state.eBarEthicsCommissionAdvisoryOpinion.slice(0, 5),
          newEBarAttorneyGeneralOpinion: this.state.eBarAttorneyGeneralOpinion.slice(0, 5),
          newEBarOtherAnnouncement: this.state.eBarOtherAnnouncement.slice(0, 5),
        });
      });
  }

  render() {
    const contentTypes = [
      { filterId: 1, filterName: 'Opinions' },
      { filterId: 2, filterName: 'Legislative Enactments' },
      { filterId: 3, filterName: 'Supreme Court of Ohio Announcements' },
      { filterId: 4, filterName: 'Board of Professional Conduct Advisory Opinions' },
      { filterId: 5, filterName: 'Ethics Commission Advisory Opinions' },
      { filterId: 6, filterName: 'Attorney General Opinions' },
      { filterId: 7, filterName: 'Other Announcements' }];

    return (
      <Wrapper>
        {this.state.loading && <LoadingOverlay />}
        <ContentArea>
          <SearchFilterLeftRail>
            <KeywordFilter
              headerText="Refine results by"
              placeholder="Keywords, Bill #, Case #"
              filterValue={this.state.keywordValue}
              filterUpdate={this.keywordFilterUpdate}
              keywordUpdate={this.keywordUpdate}
            />
            <DateRangeFilter
              end={this.state.endDate}
              filterUpdate={this.dateFilterUpdate}
              minDate={moment().subtract(1, 'years').toDate()}
              maxDate={moment().toDate()}
              start={this.state.startDate}
              startDateProps={{
                scrollableYearDropdown: true,
                showYearDropdown: true,
                yearDropdownItemNumber: 10,
              }}
              endDateProps={{
                scrollableYearDropdown: true,
                showYearDropdown: true,
                yearDropdownItemNumber: 10,
              }}
              updateStartDate={this.updateStartDate}
              updateEndDate={this.updateEndDate}
            />
            <ResourceTypeFilter
              filterOptions={contentTypes}
              filterAppendOrRemove={this.filterAppendOrRemove}
              showResults={this.state.showResults}
              filterClearAll={this.filterClearAll}
            />
            {this.state.showResults.Case && (
            <PracticeAreaFilter
              filterOptions={this.state.areasOfLaw}
              filterAppendOrRemove={this.updateAreasOfLaw}
              filterClearAll={this.filterAoLClearAll}
              groups={this.state.groups}
            />
            )}
            {this.state.showResults.Case && (
            <CourtsFilter
              filterOptions={this.state.courts}
              filterAppendOrRemove={this.updateCourts}
              filterClearAll={this.filterCourtsClearAll}
            />
            )}
          </SearchFilterLeftRail>
          {this.state.showAllResults && (
          <Results
            eBarCase={this.state.eBarCase}
            eBarLegislativeEnactment={this.state.eBarLegislativeEnactment}
            eBarSCOAnnouncement={this.state.eBarSCOAnnouncement}
            eBarBoardOfProfessionalsConduct={this.state.eBarBoardOfProfessionalsConduct}
            eBarEthicsCommissionAdvisoryOpinion={this.state.eBarEthicsCommissionAdvisoryOpinion}
            eBarAttorneyGeneralOpinion={this.state.eBarAttorneyGeneralOpinion}
            eBarOtherAnnouncement={this.state.eBarOtherAnnouncement}
            newEBarCase={this.state.newEBarCase}
            newEBarLegislativeEnactment={this.state.newEBarLegislativeEnactment}
            newEBarSCOAnnouncement={this.state.newEBarSCOAnnouncement}
            newEBarBoardOfProfessionalsConduct={this.state.newEBarBoardOfProfessionalsConduct}
            newEBarEthicsCommissionAdvisoryOpinion={
              this.state.newEBarEthicsCommissionAdvisoryOpinion}
            newEBarAttorneyGeneralOpinion={this.state.newEBarAttorneyGeneralOpinion}
            newEBarOtherAnnouncement={this.state.newEBarOtherAnnouncement}
            showAllResults={this.state.showAllResults}
            pageCountUpdate={this.pageCountUpdate}
            sortUpdate={this.sortUpdate}
            sortBy={this.state.sortBy}
            saveBookmarkHandler={this.saveBookmarkHandler}
            filterValues={this.state.filterValues}
            updateSearchFilter={this.updateSearchFilter}
            clearSearchFilters={this.clearSearchFilters}
            totalCount={this.state.totalCount}
          />
          )}
          {!this.state.showAllResults && (
          <Results
            eBarCase={this.state.eBarCase}
            eBarLegislativeEnactment={this.state.eBarLegislativeEnactment}
            eBarSCOAnnouncement={this.state.eBarSCOAnnouncement}
            eBarBoardOfProfessionalsConduct={this.state.eBarBoardOfProfessionalsConduct}
            eBarEthicsCommissionAdvisoryOpinion={this.state.eBarEthicsCommissionAdvisoryOpinion}
            eBarAttorneyGeneralOpinion={this.state.eBarAttorneyGeneralOpinion}
            eBarOtherAnnouncement={this.state.eBarOtherAnnouncement}
            newEBarCase={this.state.newEBarCase}
            newEBarLegislativeEnactment={this.state.newEBarLegislativeEnactment}
            newEBarSCOAnnouncement={this.state.newEBarSCOAnnouncement}
            newEBarBoardOfProfessionalsConduct={this.state.newEBarBoardOfProfessionalsConduct}
            newEBarEthicsCommissionAdvisoryOpinion={
              this.state.newEBarEthicsCommissionAdvisoryOpinion}
            newEBarAttorneyGeneralOpinion={this.state.newEBarAttorneyGeneralOpinion}
            newEBarOtherAnnouncement={this.state.newEBarOtherAnnouncement}
            showResults={this.state.showResults}
            showAllResults={this.state.showAllResults}
            pageCountUpdate={this.pageCountUpdate}
            sortUpdate={this.sortUpdate}
            sortBy={this.state.sortBy}
            saveBookmarkHandler={this.saveBookmarkHandler}
            filterValues={this.state.filterValues}
            updateSearchFilter={this.updateSearchFilter}
            clearSearchFilters={this.clearSearchFilters}
            totalCount={this.state.totalCount}
          />
          )}
        </ContentArea>
      </Wrapper>
    );
  }
}

EBarSearchPage.propTypes = {
  eBarCase: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string,
    caseName: PropTypes.string,
    webCitation: PropTypes.string,
    activeDate: PropTypes.string,
    casemakerLink: PropTypes.string,
    pDFLink: PropTypes.string,
    practiceId: PropTypes.number,
    courtId: PropTypes.number,
    practiceName: PropTypes.string,
    courtName: PropTypes.string,
    contentGuidString: PropTypes.string,
    dateDecidedString: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarLegislativeEnactment: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    billNumber: PropTypes.string,
    effectiveDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarSCOAnnouncement: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    subHeader: PropTypes.number,
    title: PropTypes.string,
    cite: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    effectivePostedDate: PropTypes.string,
    effectivePostedDateString: PropTypes.string,
    dateType: PropTypes.number,
    summary: PropTypes.string,
    createdDate: PropTypes.string,
    activeDate: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarBoardOfProfessionalsConduct: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarEthicsCommissionAdvisoryOpinion: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarAttorneyGeneralOpinion: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarOtherAnnouncement: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    pitle: PropTypes.string,
    partnerEntity: PropTypes.string,
    postedDate: PropTypes.string,
    summary: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    activeDate: PropTypes.string,
    createdDate: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  areasOfLaw: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  courts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  keywordValue: PropTypes.string,
  caseCount: PropTypes.number,
  enactmentCount: PropTypes.number,
  scoCount: PropTypes.number,
  conductCount: PropTypes.number,
  ethicsCount: PropTypes.number,
  attorneyCount: PropTypes.number,
  otherCount: PropTypes.number,
};

EBarSearchPage.defaultProps = {
  eBarCase: [],
  eBarLegislativeEnactment: [],
  eBarSCOAnnouncement: [],
  eBarBoardOfProfessionalsConduct: [],
  eBarEthicsCommissionAdvisoryOpinion: [],
  eBarAttorneyGeneralOpinion: [],
  eBarOtherAnnouncement: [],
  keywordValue: '',
  caseCount: 0,
  enactmentCount: 0,
  scoCount: 0,
  conductCount: 0,
  ethicsCount: 0,
  attorneyCount: 0,
  otherCount: 0,
};

EBarSearchPage.displayName = 'EBarSearchPage';

export default EBarSearchPage;
